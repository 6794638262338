<template>
  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newGroup"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
    containerMedium
  >
    <template slot="content">
      <v-card
        v-for="tenant in tenants"
        :key="tenant.tenant_id"
        elevation="4"
        style="margin-bottom:15px"
      >
        <v-card-text>
          <v-treeview
            item-disabled="disabled"
            :items="companiesTree[tenant.tenant_id]"
            :load-children="loadChildren"
            open-on-click
            transition
          >
            <template v-slot:prepend="{ item }">
              <v-icon :class="item.type == 'group' && item.disabled ? 'inactive-row' : ''">
                {{ item.icon }}
              </v-icon>
            </template>

            <template v-slot:append="{ item }">
              <template v-if="item.type == 'group'">

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!item.data.external_group"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="editGroup(item)"
                    >
                      <v-icon :class="item.type == 'group' && item.disabled ? 'inactive-row' : ''">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="showGroupUsers(item)"
                    >
                      <v-icon :class="item.type == 'group' && item.disabled ? 'inactive-row' : ''">
                        mdi-account-details
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('group_users') }}</span>
                </v-tooltip>

                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.data.external_group && isCompanyAdmin(item)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="promptRemoveGroupAccess(item)"
                    >
                      <v-icon :class="item.type == 'group' && item.disabled ? 'inactive-row' : ''">
                        mdi-home-remove
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('remove') }}</span>
                </v-tooltip>

                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!item.data.external_group"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="showExternalCompanies(item)"
                    >
                      <v-icon :class="item.type == 'group' && item.disabled ? 'inactive-row' : ''">
                        mdi-home-export-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('external_companies') }}</span>
                </v-tooltip>

                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isCompanyAdmin(item)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openSetSecurityClearanceModal(item)"
                    >
                      <v-icon :class="item.type == 'group' && item.disabled ? 'inactive-row' : ''">
                        mdi-security
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Security clearance') }}</span>
                </v-tooltip>

              </template>
            </template>

          </v-treeview>
        </v-card-text>
      </v-card>

      <DefaultModal
        :title="getModalTitle()"
        ref="modal"
        v-on:submit="save"
        v-on:modal-closed="resetSelectedGroup"
      >
        <template v-slot:content>
          <DynamicFormContent
            :fields="fields"
            v-on:tenant-change="handleSelectedTenant"
          ></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('Remove Access to Group')"
        ref="removeExternalGroupModal"
        @submit="removeExternalGroup"
        submit-button-text="remove"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('Are you sure you want to remove access to the group') }} <b>{{ selectedGroupName }}</b> ?
        </template>
      </DefaultModal>

      <SecurityClearanceModal ref="securityClearanceModal"></SecurityClearanceModal>

    </template>

  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import router from '@/router'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'
import SecurityClearanceModal from '@/components/node/SecurityClearanceModal.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import ApplicationInstanceActions from '@/store/core/application_instance/actions-types'
import GroupActions from '@/store/core/group/actions-types'
import GroupMutations from '@/store/core/group/mutations-types'
import CompanyActions from '@/store/core/company/actions-types'
import AppActions from '@/store/app/actions-types'
import ThemeActions from '@/store/core/theme/actions-types'

export default {
  name: "Group",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
    SecurityClearanceModal
  },

  computed: {
    ...mapGetters('app', [
      'applicationTitleById',
      'getIconForCoreSettingsItem'
    ]),

    ...mapGetters('core/auth', [
      'checkIsAdminInTenant'
    ]),

    ...mapState({
      fields: state => state.core.group.fields,
      applications_instances: state => state.core.application_intance.applications_instances,
      headers: state => state.core.group.tableHeaders,
      selectedGroup: state => state.core.group.selectedGroup,
      selectedTenant: state => state.core.group.selectedTenant,
      inactiveFilterValue: state => state.core.group.inactiveFilterValue
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('groups'),
          prependIcon: this.getIconForCoreSettingsItem('groups'),
        },
      ]
    },

    selectedGroupName() {
      return this.selectedGroup?.data?.group_title ?? ''
    },

    selectedThemeId() {
      let themes = this.fields.filter(field => field.name == 'theme')
      if (themes.length > 0) {
        return themes[0].value
      }

      return null
    }

  },

  data() {
    return {
      loading: false,
      groups: [],
      itemsLength: 0,
      tenants: [],
      companiesTree: [],
      actions: [
        {
          text: "create_group",
          eventToEmit: "create-new",
        },
      ]
    }
  },

  mounted() {
    this.loading = true
    let promises = []

    promises.push(this[GroupMutations.SET_SELECTED_GROUP](null))
    promises.push(this[ApplicationInstanceActions.GET_ALL]())
    promises.push(this[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.setTenants(tenants.items)
        this[GroupMutations.SET_FIELD_TENANT](tenants.items)
      }))

    Promise.all(promises)
      .finally(() => this.loading = false)
  },

  methods: {
    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('core/application_instance', [
      ApplicationInstanceActions.GET_ALL,
    ]),

    ...mapActions('core/group', [
      GroupActions.SAVE_GROUP,
      GroupActions.HANDLE_APPLICATIONS_ITEMS,
      GroupActions.GET_GROUPS_BY_COMPANY
    ]),

    ...mapActions('core/company', [
      CompanyActions.GET_COMPANIES_BY_TENANT,
      CompanyActions.DELETE_COMPANY_GROUP_RELATIONSHIP,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/theme', [
      ThemeActions.GET_TENANT_AVAILABLE_THEMES,
      ThemeActions.LINK_THEME_TO_GROUP,
    ]),

    ...mapMutations('core/group', [
      GroupMutations.SET_FIELD_TENANT,
      GroupMutations.SET_FIELD_COMPANY,
      GroupMutations.SET_SELECTED_GROUP,
      GroupMutations.SET_SELECTED_TENANT,
      GroupMutations.SET_LAST_SEARCH_TENANTS,
      GroupMutations.SET_LAST_SEARCH_COMPANIES,
      GroupMutations.SET_INACTIVE_FILTER_VAL,
      GroupMutations.SET_THEME_ITEMS
    ]),

    setTenants(items) {
      let companiesTree = []

      items.forEach(item => {
        companiesTree[item.tenant_id] = [
          {id: `tenant-${item.tenant_id}`, type: 'tenant', name: item.title, icon: 'mdi-home-account', children: [], data: item}
        ]
      })

      this.tenants = items
      this.companiesTree = companiesTree
      this.[GroupMutations.SET_LAST_SEARCH_TENANTS](items)
    },

    async loadChildren(item) {
      if (item.type == 'tenant') {
        return this.[CompanyActions.GET_COMPANIES_BY_TENANT](item.data.tenant_id)
          .then((result) => {
            let companies = []
            result.items.forEach(
              company => companies.push(
                {
                  id: `company-${company.company_id}`,
                  name: company.name,
                  type: 'company',
                  icon: 'mdi-office-building',
                  children: [],
                  data: company,
                  origin_tenant: item.data.tenant_id
                }
              )
            )

            this.[GroupMutations.SET_LAST_SEARCH_COMPANIES](result.items)

            return companies
          })
          .then(companies => (item.children.push(...companies)))
      }

      if (item.type == 'company') {
        let options = {...this.$route.query}

        if (!this.inactiveFilterValue) {
          options = {
            ...options,
            'filter[active]' : "true"
          }
        }

        let params = {companyId: item.data.company_id, options: options}

        return this.[GroupActions.GET_GROUPS_BY_COMPANY](params)
          .then((result) => {
            let groups = []
            result.items.forEach(
              group => groups.push(
                {
                  id: group.user_group_id,
                  name: (group.external_group ? `(${group.company_owner}) ` : '') + group.group_title,
                  type: 'group',
                  icon: group.external_group ? 'mdi-account-group' : 'mdi-account-multiple',
                  disabled: !group.active,
                  data: group,
                  origin_company: item.data,
                  origin_tenant: item.origin_tenant
                }
              )
            )

            return groups
          })
          .then(groups => {
            item.children = []
            item.children.push(...groups)
          })
      }
    },

    newGroup() {
      if (this.selectedGroup) {
        this.[GroupMutations.SET_SELECTED_GROUP](null)
      }

      this[GroupMutations.SET_THEME_ITEMS]([])
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[GroupActions.SAVE_GROUP]()
        .then(() => {
          let message = this.selectedGroup ? 'Group edited' : 'Group created'

          this.[ThemeActions.LINK_THEME_TO_GROUP]({groupId: this.selectedGroup.user_group_id, themeId: this.selectedThemeId})
          .then(() => {
            this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
            this.reloadData()
            this.$refs.modal.closeModal()
            this.[GroupMutations.SET_SELECTED_GROUP](null)
          })
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData(ignoreSelectedField) {
      this.companiesTree.forEach(tenants => {
        tenants.forEach(company => {
          company.children.forEach(company => {
            if (!this.selectedGroup) {
              let companyField = this.fields.find(field => { return field.name == 'company_id'})
              if (ignoreSelectedField || company.data.company_id == companyField.value) {
                this.loadChildren(company)
              }
            }

            if (this.selectedGroup) {
              company.children.forEach(group => {
                if (group.id == this.selectedGroup.user_group_id) {
                  group.name = this.fields.find(field => { return field.name == 'group_title' }).value
                  group.disabled = !this.fields.find(field => { return field.name == 'active' }).value
                  group.data.group_title = this.fields.find(field => { return field.name == 'group_title' }).value
                  group.data.active = this.fields.find(field => { return field.name == 'active' }).value
                  group.data.application_ids = this.fields.find(field => { return field.name == 'application_ids' }).value
                  group.data.application_instance_ids = this.fields.find(field => { return field.name == 'application_instance_ids' }).value
                  group.data.theme_id = this.fields.find(field => { return field.name == 'theme'}).value
                }
              })
            }
          })
        })
      })
    },

    editGroup(item) {
      this[GroupMutations.SET_SELECTED_GROUP](item.data)

      let tenant = this.tenants.find(f => {return f.tenant_id == item.data.tenant_id})

      if (tenant) {
        this[GroupActions.HANDLE_APPLICATIONS_ITEMS]({
          applicationsIds: tenant.application_ids,
          applicationsInstanceIds: tenant.app_instance_ids
        })

        this[ThemeActions.GET_TENANT_AVAILABLE_THEMES](tenant.tenant_id)
          .then((result) => {
              this[GroupMutations.SET_THEME_ITEMS](result.items)
          })
      }

      this.$refs.modal.openModal()
    },

    promptRemoveGroupAccess(item) {
      this.[GroupMutations.SET_SELECTED_GROUP](item)

      this.$refs.removeExternalGroupModal.openModal()
    },

    removeExternalGroup() {
      const companyId = this.selectedGroup.origin_company.company_id
      const userGroupId = this.selectedGroup.id

      this.[CompanyActions.DELETE_COMPANY_GROUP_RELATIONSHIP]({companyId, userGroupId})
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Access to group removed!'))

          this.$refs.removeExternalGroupModal.closeModal()

          this.[GroupMutations.SET_SELECTED_GROUP](null)

          this.reloadData(true)
        })
        .catch(() => this.$refs.removeExternalGroupModal.submitting = false)
    },

    showGroupUsers(item) {
      this.[GroupMutations.SET_SELECTED_GROUP](item.data)

      let params = {groupId: item.id, companyId: item.origin_company.company_id}

      if (item.data.external_group) {
        params.originTenant = item.origin_tenant
      }

      router.push({name: 'GroupUsers', params: params})
    },

    showExternalCompanies(item) {
      router.push({name: 'ExternalCompanies', params: {groupId: item.id}})
    },

    getModalTitle() {
      return this.selectedGroup ? this.$t('Edit Group #' + this.selectedGroup.user_group_id) : this.$t('New Group')
    },

    switchInactive() {
      this[GroupMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)
      this.reloadData(true)
    },

    handleSelectedTenant () {
      let tenantField = this.fields.find(f => f.name == 'tenant_id')

      let selectedTenant = null

      if (tenantField.value) {
        selectedTenant = tenantField.items.find(f => f.tenant_id == tenantField.value)
      }

      if (selectedTenant == this.selectedTenant) {
        return
      }

      this[GroupMutations.SET_SELECTED_TENANT](selectedTenant)

      if (selectedTenant) {
        this.$refs.modal.submitting = true

        this[CompanyActions.GET_COMPANIES_BY_TENANT](selectedTenant.tenant_id)
          .then((result) => {
            this[GroupMutations.SET_FIELD_COMPANY](result.items)

            this[GroupActions.HANDLE_APPLICATIONS_ITEMS]({
              applicationsIds: selectedTenant.application_ids,
              applicationsInstanceIds: selectedTenant.app_instance_ids
            })

            this.$refs.modal.submitting = false
          })
          .catch(() => this.$refs.modal.submitting = false)

        this[ThemeActions.GET_TENANT_AVAILABLE_THEMES](selectedTenant.tenant_id)
          .then((result) => {
              this[GroupMutations.SET_THEME_ITEMS](result.items)
          })

        return
      }

      this[GroupMutations.SET_THEME_ITEMS]([])

      this[GroupMutations.SET_FIELD_TENANT]([])
      this[GroupMutations.SET_FIELD_COMPANY]([])
      this[GroupActions.HANDLE_APPLICATIONS_ITEMS]({
        applicationsIds: [],
        applicationsInstanceIds: []
      })
    },

    resetSelectedGroup() {
      this.[GroupMutations.SET_SELECTED_GROUP](null)
    },

    isCompanyAdmin(item) {
      const tenantId = item.origin_tenant

      return this.checkIsAdminInTenant(tenantId)
    },

    openSetSecurityClearanceModal(item) {
      const tenantId = item.origin_tenant

      this.$refs.securityClearanceModal.open(item.id, item.name, tenantId)
    }
  }
}

</script>