<template>
  <DefaultModal
    ref="modal"
    @submit="saveClearance"
    :loading="loading"
    scrollable
  >
    <template v-slot:title>
      <span class="title-text">
        <span class="headline">{{ $t('Security clearance') }}</span>
        <span class="font-italic normal"> - {{ groupName }}</span>
      </span>
    </template>

    <template v-slot:content>
      <v-sheet min-height="400">
        <div
          v-for="(securityGroup, index) in securityGroups"
          :key="securityGroup.security_group_id"
        >
          <v-treeview
            :items="securityGroup.tree"
            :open.sync="allMarks[securityGroup.security_group_id]"
            :ref="'secgroup_' + securityGroup.security_group_id"
            selection-type="independent"
            disable-per-node
            transition
            open-all
          >
            <template
              v-slot:prepend="{ item }"
            >
              <template v-if="! item.title_item">
                <v-checkbox
                  v-if="securityGroup.user_cardinality == 'single'"
                  v-model="clearanceBySecurityGroup[securityGroup.security_group_id]"
                  :value="item.id"
                  :disabled="item.disabled"
                  on-icon="mdi-radiobox-marked"
                  off-icon="mdi-radiobox-blank"
                  @click.native.prevent.stop.capture="clearanceChangeSingleCardinality(securityGroup.security_group_id, item.id)"
                ></v-checkbox>

                <v-checkbox
                  v-else
                  v-model="clearanceBySecurityGroup[securityGroup.security_group_id]"
                  :disabled="item.disabled"
                  :value="item.id"
                  :off-icon="item.has_mark_above ? '$checkbox_eye' : '$checkboxOff'"
                  :class="item.has_mark_above ? 'primary-color' : ''"
                  @change="clearanceChange(item)"
                ></v-checkbox>
              </template>
            </template>

            <template
              v-slot:label="{ item }"
            >
              <template v-if="item.title_item">
                <h3 class="sg-key">
                  <span>{{ securityGroup.security_group_key }}</span>
                </h3>
              </template>

              <template v-else>
                <span>{{item.name}}</span>
              </template>
            </template>
          </v-treeview>

          <v-divider
            v-if="index < securityGroups.length - 1"
            :key="index"
          ></v-divider>
        </div>
      </v-sheet>
    </template>
  </DefaultModal>
</template>

<style scoped>
  >>>span.v-icon {
    color: var(--v-primary-base)
  }

  .title-text {
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sg-key {
    display: inline;
  }

  >>>.v-treeview-node__prepend:empty {
    min-width: unset;
  }
</style>

<script>
import Vue from 'vue'

import { mapActions } from 'vuex'

import AppActions from '@/store/app/actions-types'
import ClearanceActions from '@/store/operation/clearance/actions-types'
import SecurityGroupActions from '@/store/operation/security_group/actions-types'

import DefaultModal from '@/components/DefaultModal'

export default {
  name: 'SecurityClearanceModal',

  components: {
    DefaultModal,
  },

  data: () => ({
    loading: true,
    groupId: null,
    groupName: "",
    tenantId: null,
    allClearance: [],
    securityGroups: [],
    allMarks: [],
    clearanceBySecurityGroup: [],
  }),

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    ...mapActions('operation/security_group', [
      SecurityGroupActions.GET_SECURITY_GROUPS_BY_TENANT,
      SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP,
    ]),

    ...mapActions('operation/clearance', [
      ClearanceActions.GET_CLEARANCE_BY_GROUP,
      ClearanceActions.SAVE_SECURITY_CLEARANCE
    ]),

    open(groupId, groupName, tenantId) {
      this.groupId = groupId
      this.groupName = groupName
      this.tenantId = tenantId

      this.fetchData()
      this.$refs.modal.openModal()
    },

    close() {
      this.groupId = null
      this.groupName = ""

      this.$refs.modal.closeModal()
    },

    fetchData() {
      this.loading = true

      const clearancePromise = this[ClearanceActions.GET_CLEARANCE_BY_GROUP](this.groupId)
      clearancePromise.then((result) => {
        this.allClearance = result
      })

      const securityGroupsPromise = this[SecurityGroupActions.GET_SECURITY_GROUPS_BY_TENANT](this.tenantId)
      securityGroupsPromise.then((result) => {
        let securityGroups = [...result.items]

        this.clearanceBySecurityGroup = [...[]]

        securityGroups.forEach(secGroup => {
          this.findSecurityMarksBySecurityGroup(secGroup)
        })

        this.securityGroups = [...securityGroups]
      })

      Promise.all([clearancePromise, securityGroupsPromise])
        .finally(() => this.loading = false)
    },

    findSecurityMarksBySecurityGroup(secGroup) {
      this[SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP](secGroup.security_group_id)
        .then((result) => {
          let allMarks = result.items.map(mark => mark.security_mark_id)
          this.allMarks[secGroup.security_group_id] = allMarks.concat(secGroup.security_group_key)

          Vue.set(secGroup, 'marks', result.items)

          this.setClearanceBySecurityGroup(secGroup)

          Vue.set(secGroup, 'tree', this.fetchSecurityGroupTreeviewItems(secGroup))
        })
    },

    setClearanceBySecurityGroup(securityGroup) {
      const securityGroupId = securityGroup.security_group_id

      if (!this.allMarks.length || !this.allMarks[securityGroupId]) {
        return
      }

      const markIds = this.allMarks[securityGroupId]

      let selectedClearance = this
        .allClearance
        .filter(clearance => markIds.includes(clearance.security_mark_id))
        .map(mark => mark.security_mark_id)

      Vue.set(this.clearanceBySecurityGroup, securityGroupId, selectedClearance)
    },

    fetchSecurityGroupTreeviewItems(securityGroup) {
      let item = {
        id: securityGroup.security_group_key,
        title_item: true,
        children: [],
      }

      if (securityGroup.match_type == 'tree') {
        securityGroup.marks.forEach((mark) => {
          if (mark.mark_parent == null) {
            item.children.push(this.buildTreeviewItem(mark, securityGroup.marks))
          }
        })
      }

      if (securityGroup.match_type != 'tree') {
        securityGroup.marks.forEach(mark => {
          item.children.push(this.buildTreeviewItem(mark))
        })
      }

      return [item]
    },

    fetchTreeviewChildrenItems(securityMarkId, marks) {
      let items = []

      marks.forEach(mark => {
        if (mark.mark_parent == securityMarkId) {
          items.push(this.buildTreeviewItem(mark, marks))
        }
      })

      return items
    },

    buildTreeviewItem(mark, treeMarks = null) {
      const clearance = this.clearanceBySecurityGroup[mark.security_group_id]
        .find(clearance => clearance == mark.security_mark_id)

      const markTreeUpwardsMarks = mark.tree_path_up.split(',').map( Number )

      const hasMarkAbove = this.hasMarkInTreePath(mark, markTreeUpwardsMarks)

      let item =
      { ...mark,
        id: mark.security_mark_id,
        name: mark.security_mark_key,
        has_clearance: clearance ? true : false,
        has_mark_above: hasMarkAbove,
      }

      if (treeMarks) {
        item = 
        {...item,
          children: this.fetchTreeviewChildrenItems(mark.security_mark_id, treeMarks),
        }
      }

      return item
    },

    hasMarkInTreePath(mark, treePath) {
      const matchedClearances = this.clearanceBySecurityGroup[mark.security_group_id].filter(
        clearance => treePath.includes(clearance) && clearance != mark.security_mark_id
      )

      return (matchedClearances.length > 0)
    },

    clearanceChange(mark) {
      const markTreeDownwardsMarks = mark.tree_path_down.split(',').map( Number );
      const markTreeUpwardsPath = mark.tree_path_up.split(',').map( Number );

      const marksToUnclearance = [...markTreeDownwardsMarks, ...markTreeUpwardsPath]

      const filteredClearances = this.clearanceBySecurityGroup[mark.security_group_id].filter(
        clearance => (! marksToUnclearance.includes(clearance)) || clearance == mark.security_mark_id
      )

      this.clearanceBySecurityGroup[mark.security_group_id] = [...filteredClearances]

      this.updateSecurityGroupTree(mark.security_group_id)
    },

    clearanceChangeSingleCardinality(secGroupId, value) {
      const currentValue = this.clearanceBySecurityGroup[secGroupId]

      const newValue = currentValue == value ? [] : [value]

      Vue.set(
        this.clearanceBySecurityGroup,
        secGroupId,
        newValue
      )

      this.updateSecurityGroupTree(secGroupId)
    },

    updateSecurityGroupTree(securityGroupId) {
      const securityGroup = this.securityGroups.find(sg => sg.security_group_id == securityGroupId)
      const sgIndex = this.securityGroups.indexOf(securityGroup)

      Vue.set(this.securityGroups[sgIndex], 'tree', this.fetchSecurityGroupTreeviewItems(securityGroup))
    },

    saveClearance() {
      let clearance = []

      this.clearanceBySecurityGroup.forEach((items, index) => {
        clearance.push({group_id: this.groupId, security_group_id: index, security_marks: items})
      })

      this[ClearanceActions.SAVE_SECURITY_CLEARANCE](clearance)
        .then(() => {
          this.$refs.modal.submitting = false
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Success on saving clearance'))
          this.$refs.modal.closeModal()
        })
        .catch(() => this.$refs.modal.submitting = false)
    }
  },
}
</script>
